<template>
  <page-main
    title="User Options"
    :aside="[]"
    nextText=""
    buttonTabIndex="-1"
    eventName="nav"
    noBottom
  >
    <v-container>
      <v-row>
        <v-col cols="12" class="r-col">
          <div class="text-h6">Remove All Resume Data</div>
          <div class="text-body-1 text--secondary">
            This will remove all of your resume data. You will not be able to
            recover it.
          </div>
          <div class="pt-3">
            <v-btn outlined v-if="removeClick == 0" @click="removeFirst()"
              >Remove all Data</v-btn
            >
            <v-btn
              solid
              color="error"
              v-if="removeClick == 1"
              @click="removeFinal()"
              :disabled="!canRemove"
              :loading="loadingRemove"
            >
              Click again to remove data
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row class="pt-5">
        <v-col cols="12" class="r-col">
          <div class="text-h6">Delete Your Account</div>
          <div class="text-body-1 text--secondary">
            This will remove all resume data, as well as remove your account and
            log you out. You will not be able to recover any information.
          </div>
          <div class="pt-3">
            <v-btn outlined v-if="deleteClick == 0" @click="deleteFirst()"
              >Delete Account</v-btn
            >
            <v-btn
              solid
              color="error"
              v-if="deleteClick == 1"
              @click="deleteFinal()"
              :disabled="!canDelete"
              :loading="loadingDelete"
            >
              Click again to delete account
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </page-main>
</template>

<script>
// @ is an alias to /src
import * as optionsApi from "../api/OptionsApi";
import { mapMutations } from "vuex";

export default {
  name: "Options",
  data: () => ({
    removeClick: 0,
    canRemove: false,
    loadingRemove: false,
    deleteClick: 0,
    canDelete: false,
    loadingDelete: false,
  }),
  components: {},
  async mounted() {},
  methods: {
    ...mapMutations(["removeResume"]),
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
    removeFirst() {
      this.removeClick = 1;
      this.loadingRemove = true;
      setTimeout(() => {
        this.canRemove = true;
        this.loadingRemove = false;
      }, 1000);
    },
    async removeFinal() {
      this.loadingRemove = true;
      this.canRemove = false;
      await optionsApi.PostRemoveAllResumes();
      this.removeResume();
      this.nav("/Resume");
    },
    deleteFirst() {
      this.deleteClick = 1;
      this.loadingDelete = true;
      setTimeout(() => {
        this.canDelete = true;
        this.loadingDelete = false;
      }, 1250);
    },
    async deleteFinal() {
      this.loadingDelete = true;
      this.canDelete = false;
      await optionsApi.PostRemoveAccount();
      this.removeResume();
      this.nav("/Logout");
    },
  },
  computed: {},
};
</script>
